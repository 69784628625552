import store from '@/store'
export default function setOptions () {
  const { config, deviceList } = store.state.device
  return {
    tooltip: {
      triggerOn: 'click',
      enterable: true,
      borderWidth: 0,
      padding: 0
      // formatter: res => {
      //   const { id } = res.data
      //   listenTooltipClick(id)
      //   return TooltipHtml(res.data)
      // }
    },
    bmap: {
      zoom: config?.zoom || 5,
      center: config?.center.split(',') || '',
      roam: true,
      mapStyle: {
      }
    },
    series: [
      {
        // name: 'pm2.5',
        type: 'scatter',
        coordinateSystem: 'bmap',
        data: deviceList,
        symbolSize: 10,
        encode: {
          value: 2
        }
      }
    ]
  }
}
