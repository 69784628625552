const html = info => {
  console.log('info', info)
  let showList = ''
  Object.keys(info.data).forEach(key => {
    showList += `
      <div class="item flex_jcsb">
        <span class="label ellipsis-1">${key}</span>
        <span class="value">${info.data[key]}</span>
      </div>
    `
  })
  return `
  <div class="tooltip_dom">
    <div class="header flex_jcsb_ac">
      <span class="title">设备信息</span>
    </div>
    <div class="main">
      <div class="part">
        <div class="item">
          <span class="label">设备名称:</span>
          <span class="value">${info.name}</span>
        </div>
        <div class="item">
          <span class="label">设备编号:</span>
          <span class="value">${info.sn}</span>
        </div>
        <div class="item">
          <span class="label">状态:</span>
          <span class="value ${info.status ? 'close' : 'open'}">${info.status ? '关闭' : '正常'}</span>
        </div>
        <div class="item">
          <span class="label">时间:</span>
          <span class="value">${info.time_show}</span>
        </div>
      </div>
      <div class="part params">
        ${showList}
        <div class="more flex_jc_ac">
          <span id="tooltipDom">查看详情</span>
          <span class="iconfont icon-iconright"></span>
        </div>
      </div>
    </div>
  </div>
  `
}
export default html
