import getBMap from '@/utils/baiduMap.js'
export default async function mapControl (type, bmapModal) {
  const buttonInfo = { // 按钮数据
    common: {
      name: '地图', right: 136, backgroundColor: '#15BB44', mapType: window.BMAP_NORMAL_MAP
    },
    satellite: {
      name: '卫星地图', right: 32, backgroundColor: '#0095EA', mapType: window.BMAP_HYBRID_MAP
    }
  }
  // 设置自定义控件构造函数的prototype属性为MapTypeControl的实例，以便继承控件基类
  const MapControl = () => {}
  const BMap = await getBMap()
  // MapControl.prototype = new BMap.Control()
  MapControl.prototype = new BMap.MapTypeControl({
    // 设置按钮的位置
    offset: new BMap.Size(`${buttonInfo[type].right}`, 30)
  })
  MapControl.prototype.initialize = function (map) {
  // 创建一个DOM元素
    const div = document.createElement('div')
    // 设置样式
    div.style.lineHeight = '36px'
    div.style.width = '88px'
    // div.style.right = `${buttonInfo[type].right}px`
    // div.style.top = '30px'
    div.style.fontSize = '16px'
    div.style.borderRadius = '4px'
    div.style.color = '#ffffff'
    div.style.textAlign = 'center'
    div.style.cursor = 'pointer'
    div.style.backgroundColor = buttonInfo[type].backgroundColor
    div.innerHTML = buttonInfo[type].name
    div.onclick = function (e) { // 按钮被点击，这里可以增加回调函数处理其他逻辑
      map.setMapType(buttonInfo[type].mapType)
    }
    // 添加DOM元素到地图中
    map.getContainer().appendChild(div)
    // 将DOM元素返回
    return div
  }

  // 创建控件
  const control = new MapControl()

  // 添加到地图当中
  bmapModal.addControl(control)
}
