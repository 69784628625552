import createControlButton from '../MapControl/createControlButton';
import { useRouter } from 'vue-router';

var showHeatMap = function showHeatMap() {
  var router = useRouter();

  var routerLink = function routerLink() {
    // 查看热力图
    // const { href } = router.resolve({
    //   path: '/heatMap'
    // })
    // window.open(href, '_blank')
    router.push({
      path: '/heatMap'
    });
  };

  return {
    routerLink: routerLink
  };
};

var scatterMapButton = function scatterMapButton() {
  // 创建散点图按钮
  var createButton = function createButton(bmap) {
    createControlButton('common', bmap); // 创建普通地图按钮

    createControlButton('satellite', bmap); // 创建卫星地图按钮
  };

  return {
    createButton: createButton
  };
};

export default {
  name: 'MapControl',
  setup: function setup() {
    var _showHeatMap = showHeatMap(),
        routerLink = _showHeatMap.routerLink;

    var _scatterMapButton = scatterMapButton(),
        createButton = _scatterMapButton.createButton;

    return {
      routerLink: routerLink,
      createButton: createButton
    };
  }
};