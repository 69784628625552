import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1f6ca840"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container",
  id: "haha"
};
var _hoisted_2 = {
  ref: "chartDom",
  class: "chart_dom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_MapControl = _resolveComponent("MapControl");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, null, 512), _createVNode(_component_MapControl, {
    ref: "mapControlDom"
  }, null, 512)]);
}